(function ($, viewport) {

	$(function () {
		$(window).resize(
		   viewport.changed(function() {
			   handleResize(viewport);
		   })
	   );
		handleResize(viewport);
	});

	function handleResize(viewport) {
		if(viewport.is('>=md')) {
			activateMenuState();
		} else {
			deactivateMenuState();
		}
	}

	/**
	 * activate event driven menu actions
	 */
	function activateMenuState() {
		$('#desktop-navbar-collapse').on('show.bs.collapse', function () {
			setMenuStateToClose()
		});
		$('#desktop-navbar-collapse').on('hide.bs.collapse', function () {
			setMenuStateToOpen();
		});
		$('.menu-open__text').show()
	}

	/**
	 * Deactivate event driven menu actions
	 */
	function deactivateMenuState() {
		$('#desktop-navbar-collapse').off('show.bs.collapse');
		$('#desktop-navbar-collapse').off('hide.bs.collapse');
		$('.menu-open__text').hide()
	}

	/**
	 * setting the menu to closed state
	 */
	function setMenuStateToClose() {
		$('.menu-open__text').hide().text('סגור').fadeIn(700);
	}

	/**
	 * set menu to a closed state
	 */
	function setMenuStateToOpen() {
		$('.menu-open__text').hide().text('פתח תפריט').fadeIn(700);
	}


})(jQuery, ResponsiveBootstrapToolkit);