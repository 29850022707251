$(document).on('click', '.drawer__close-btn', function() {
	closeDrawer();
});

$(document).on('click', '.drawer__open-btn', function() {
	openDrawer();
});

function closeDrawer() {
	$(document).trigger('drawer.closing');
	$('.drawer__content').css({ "visibility": "hidden", opacity: 0, "margin-right": "-50px" }); //.hide();
	$('.drawer__close-btn').css({ "visibility": "hidden", opacity: 0, "margin-right": "-50px" }); //.hide();
	$('.drawer__open-btn').css({ "visibility": "visible", opacity: 1 }); //.show();
	$(document).trigger('drawer.closed');
}

function openDrawer() {
	$(document).trigger('drawer.opening');
	$('.drawer__content').css({ "visibility": "visible", opacity: 1, "margin-right": "0" }); //.show();
	$('.drawer__close-btn').css({ "visibility": "visible", opacity: 1, "margin-right": "0" }); //.show();
	$('.drawer__open-btn').css({ "visibility": "hidden", opacity: 0 }); //.hide();
	$(document).trigger('drawer.opened');
}

