(function ($, viewport) {

	$(function () {
		$(window).resize(
			viewport.changed(function() {
				handleResize(viewport);
			})
		);
		handleResize(viewport);
	});

	function handleResize(viewport) {
		if(viewport.is('>=md')) {
			activateScroll();
		} else {
			deactivateScroll();
		}
	}

})(jQuery, ResponsiveBootstrapToolkit);

/**
 * Activates it-scrolls
 */
function activateScroll() {
	$(".it-scroll").mCustomScrollbar({
		scrollbarPosition: "outside",
		theme: "dark"
	})
};

/**
 * Deactivates it-scrolls
 */
function deactivateScroll() {
	$(".it-scroll").mCustomScrollbar("destroy");
}
